<template>
  <div id="screen">
    <div id="urlInfo"></div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    imgList: Array
  },
  data() {
    return {

    }
  },
  mounted() {
    this.m3D()
    const that = this
    setTimeout(() => {
      that.m3D().init(
        that.imgList
      )
    }, 500)
  },
  methods: {
    m3D() {
      const that = this
      /* ---- private vars ---- */
      var diapo = [],
        imb,
        scr,
        selected,
        urlInfo,
        camera = { x: 0, y: 0, z: -650, s: 0, fov: 500 },
        nw = 0,
        nh = 0;
      /* ==== camera tween methods ==== */
      camera.setTarget = function (c0, t1, p) {
        if (Math.abs(t1 - c0) > .1) {
          camera.s = 1;
          camera.p = 0;
          camera.d = t1 - c0;
          if (p) {
            camera.d *= 2;
            camera.p = 9;
          }
        }
      }
      camera.tween = function (v) {
        if (camera.s != 0) {
          camera.p += camera.s;
          camera[v] += camera.d * camera.p * .01;
          if (camera.p == 10) camera.s = -1;
          else if (camera.p == 0) camera.s = 0;
        }
        return camera.s;
      }
      /* ==== diapo constructor ==== */
      var Diapo = function (n, img, x, y, z) {
        if (img) {
          this.id = img.id;
          // this.title = img.title;
          // this.color = img.color;
          this.isLoaded = false;
          if (document.createElement("canvas").getContext) {
            /* ---- using canvas in place of images (performance trick) ---- */
            this.srcImg = new Image();
            this.srcImg.src = `https://gsmm.xcforest.com/gsmm/api/v1/statics/${img.img_src}`;
            this.img = document.createElement("canvas");
            this.canvas = true;
            scr.appendChild(this.img);
          } else {
            /* ---- normal image ---- */
            this.img = document.createElement('img');
            this.img.src = `https://gsmm.xcforest.com/gsmm/api/v1/statics/${img.img_src}`;
            scr.appendChild(this.img);
          }
          /* ---- on click event ---- */
          this.img.onclick = function () {
            if (camera.s) return;
            if (this.diapo.isLoaded) {
              if (this.diapo.urlActive) {
                /* ---- jump hyperlink ---- */
                that.$router.push({ path: '/explain', query: { id: this.diapo.id } })
              } else {
                /* ---- target positions ---- */
                camera.tz = this.diapo.z - camera.fov;
                camera.tx = this.diapo.x;
                camera.ty = this.diapo.y;
                /* ---- disable previously selected img ---- */
                if (selected) {
                  selected.img.setAttribute("class", '');
                  selected.img.style.cursor = "pointer";
                  selected.urlActive = false;
                  urlInfo.style.visibility = "hidden";
                }
                interpolation(false);
                selected = this.diapo;
              }
            }
          }
          imb = this.img;
          this.img.diapo = this;
          this.zi = 25000;
        } else {
          /* ---- transparent div ---- */
          this.img = document.createElement('div');
          this.isLoaded = true;
          this.img.setAttribute("class", "fog");
          scr.appendChild(this.img);
          this.w = 300;
          this.h = 300;
          this.zi = 15000;
        }
        /* ---- object variables ---- */
        this.x = x;
        this.y = y;
        this.z = z;
        this.css = this.img.style;
      }
      /* ==== main 3D animation ==== */
      Diapo.prototype.anim = function () {
        if (this.isLoaded) {
          /* ---- 3D to 2D projection ---- */
          var x = this.x - camera.x;
          var y = this.y - camera.y;
          var z = this.z - camera.z;
          if (z < 20) z += 5000;
          var p = camera.fov / z;
          var w = this.w * p;
          var h = this.h * p;
          /* ---- HTML rendering ---- */
          this.css.position = 'absolute';
          this.css.left = Math.round(nw + x * p - w * .5) + 'px';
          this.css.top = Math.round(nh + y * p - h * .5) + 'px';
          this.css.width = Math.round(w) + 'px';
          this.css.height = Math.round(h) + 'px';
          this.css.zIndex = this.zi - Math.round(z);
          this.css.background = 'rgba(255, 255, 255, 0.5)'
        } else {
          /* ---- image is loaded? ---- */
          this.isLoaded = this.loading();
        }
      }
      /* ==== onload initialization ==== */
      Diapo.prototype.loading = function () {
        if ((this.canvas && this.srcImg.complete) || this.img.complete) {
          if (this.canvas) {
            /* ---- canvas version ---- */
            this.w = this.srcImg.width;
            this.h = this.srcImg.height;
            this.img.width = this.w;
            this.img.height = this.h;
            var context = this.img.getContext("2d");
            context.drawImage(this.srcImg, 0, 0, this.w, this.h);
          } else {
            /* ---- plain image version ---- */
            this.w = this.img.width;
            this.h = this.img.height;
          }
          return true;
        }
        return false;
      }
      var resize = function () {
        nw = scr.offsetWidth * .5;
        nh = scr.offsetHeight * .5;
      }
      var interpolation = function(bicubic) {
        var i = 0, o;
        while (o = diapo[i++]) {
          if (o.but) {
            o.css.msInterpolationMode = bicubic ? 'bicubic' : 'nearest-neighbor'; // makes IE8 happy
            o.css.imageRendering = bicubic ? 'optimizeQuality' : 'optimizeSpeed'; // does not really work...
          }
        }
      }
      var init = function (data) {
        scr = document.getElementById("screen");
        urlInfo = document.getElementById("urlInfo");
        resize();
        /* ---- loading images ---- */
        var i = 0,
          o,
          n = data.length;
        while (o = data[i++]) {
          /* ---- images ---- */
          var x = 1000 * ((i % 4) - 1.5);
          var y = Math.round(Math.random() * 4000) - 2000;
          var z = i * (5000 / n);
          diapo.push(new Diapo(i - 1, o, x, y, z));
          /* ---- transparent frames ---- */
          var k = diapo.length - 1;
          for (var j = 0; j < 2; j++) {
            var x = Math.round(Math.random() * 4000) - 2000;
            var y = Math.round(Math.random() * 4000) - 2000;
            diapo.push(new Diapo(k, null, x, y, z + 100));
          }
        }
        /* ---- start engine ---- */
        run();
      }
      var run = function () {
        if (camera.tx) {
          if (!camera.s) camera.setTarget(camera.x, camera.tx);
          var m = camera.tween('x');
          if (!m) camera.tx = 0;
          /* ---- y axis move ---- */
        } else if (camera.ty) {
          if (!camera.s) camera.setTarget(camera.y, camera.ty);
          var m = camera.tween('y');
          if (!m) camera.ty = 0;
          /* ---- z axis move ---- */
        } else if (camera.tz) {
          if (!camera.s) camera.setTarget(camera.z, camera.tz);
          var m = camera.tween('z');
          if (!m) {
            /* ---- animation end ---- */
            camera.tz = 0;
            interpolation(true);
            /* ---- activate hyperlink ---- */
            if (selected.id) {
              selected.img.style.cursor = "pointer";
              selected.urlActive = true;
              selected.img.setAttribute("class", "href");
              urlInfo.diapo = selected;
              urlInfo.onclick = selected.img.onclick;
              // urlInfo.innerHTML = selected.title || selected.url;
              urlInfo.style.visibility = "visible";
              urlInfo.style.color = selected.color || "#fff";
              urlInfo.style.top = Math.round(selected.img.offsetTop + selected.img.offsetHeight - urlInfo.offsetHeight - 5) + "px";
              urlInfo.style.left = Math.round(selected.img.offsetLeft + selected.img.offsetWidth - urlInfo.offsetWidth - 5) + "px";
            } else {
              selected.img.style.cursor = "default";
            }
          }
        }
        /* ---- anim images ---- */
        var i = 0, o;
        while (o = diapo[i++]){
          o.anim();
        }
        /* ---- loop ---- */
        setTimeout(run, 32);
      }
      return {
        init: init
      }
    }
  }
}
</script>

<style lang='less' scoped>
#screen {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px);
  background: #999;
  overflow: hidden;
}

#screen img,
canvas {
  position: absolute;
  left: -9999px;
  cursor: pointer;
  image-rendering: optimizeSpeed;

}

#screen .href {
  border: #FFF dotted 1px;
}

#urlInfo {
  position: absolute;
  // background: url('@/assets/images/r.gif') no-repeat 0 4px;
  visibility: hidden;
  z-index: 30000;
  padding-left: 12px;
  cursor: pointer;
}
</style>
