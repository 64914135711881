<template>
  <div class="index">
    <!-- 首页 -->
    <!-- banner -->
<!--    新banner-->
<!--    <Banner :imgList="bannerList"></Banner>-->
    <!-- <BannerWall :imgList="bannerList"></BannerWall> -->
    <!--    以下旧banner-->
     <div class="banner">
      <swiper ref="mySwiper" class="swiper" :options="swiperOption" @click-slide="handleClickSlide"
              v-if="ShowBanner && bannerList.length>0">
        <swiper-slide v-for="(banner,index) in bannerList" :key="index">
          <div class="zoumadeng">
            <img
                :src="'/gsmm/api/v1/statics/' + banner.img_src"
                alt
                style="width:100%;height:calc(100vh - 90px)"
            />
            <div class="title">
              <div class="name">{{ banner.title }}</div>
              <div class="center">{{ banner.description }}</div>
              <div class="img"
                   style="margin-top: 10px;display: flex;justify-content: space-between;align-items: flex-end;">
                <div>
                  扫扫二维码了解更多故事...
                </div>
                <img style="width: 70px;height:70px" :src="'/gsmm/api/v1/' + banner.code_src" alt="">
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- 分页器 -->
         <div class="swiper-pagination" slot="pagination"></div>
        <!-- 左右箭头 -->
        <!-- @click="prev" -->
<!--         <div class="swiper-button-prev" slot="button-prev"></div>-->
        <!-- @click="next" -->
<!--         <div class="swiper-button-next" slot="button-next"></div>-->
       </swiper>

      <div class="jiantou">
        <img id="footer" src="../../assets/jiantou.png" alt/>
      </div>
    </div>
<!--    以上旧banner-->
    <div class="bg">
      <div class="shuiping animate__animated">
        <div
            class="list"
            v-for="(item,index) in shuipingList"
            :key="index"
            :style="{ 'margin-left': index == 0 ? '0' : '' }"
        >
          <img
              :src="require('../../assets/0' + index + '.png')"
              alt
              style="width:100%;height:100%;"
          />
          <div class="item">
            <div class="title">{{ item.title }}</div>
            <div class="num">
              <animate-number
                  v-if="show && numItem[item.num] && numItem[item.num] > 0"
                  from="0"
                  :to="numItem[item.num]"
              ></animate-number>
              <span v-if="numItem[item.num] == 0">{{ numItem[item.num] }}</span>
              株
            </div>
          </div>
        </div>
      </div>
      <!-- <Background :style="{ 'width': '100%','padding':'0 18% 40px 18%'}"/> -->
      <div class="chuizhi">
        <div class="chuizhi1">
          <template v-for="(item,index) in chuizhiList">
            <div
                class="list"
                @click="handleClick(index)"
                :key="index"
                @mouseenter="handleMouseenter(index)"
                v-if="total > 0 || (total == 0 && index > 0)"
                @mouseleave="handleMouseleave"
                :style="{ 'margin-left': index == 0 ? '0' : '', 'margin-top': MouseIndex == index ? '-30px' : index % 2 == 0 ? '0' : '40px' }">
              <img :src="require('../../assets/' + (index + 1) + '.jpg')"/>
              <div class="mengceng"></div>
              <div class="item">
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </template>
          <div
              class="list"
              @click="handleClick(5)"
              v-if="total == 0"
              @mouseenter="handleMouseenter(5)"
              @mouseleave="handleMouseleave"
              :style="{ 'margin-top': MouseIndex == 5 ? '-40px' : '0' }"
          >
            <img
                :src="require('../../assets/new.jpg')"
                alt
            />
            <div class="mengceng"></div>
            <div class="item"> 
              <div class="title">新闻宣传</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import treeVue from '../empowerment/tree.vue';
import Background from '../components/background.vue'
import Banner from '../components/banner.vue'
import BannerWall from '../components/bannerWall.vue'
export default {
  data() {
    return {
      shuipingList: [
        {
          title: '一级古树',
          num: 'one',
        },
        {
          title: '二级古树',
          num: 'two',
        },
        {
          title: '三级古树',
          num: 'three',
        },
        {
          title: '名木',
          num: 'tree',
        },
      ],
      chuizhiList: [
        {
          title: '数字赋能'
        },
        {
          title: '古树文化',
        },
        {
          title: '乡村振兴',
        },
        {
          title: '成功案例',
        },
        // {
        //     title: '新闻宣传',
        // },
      ],
      img: null,
      num: 0,
      timer: null,
      MouseIndex: -1,
      swiperOption: {
        effect: 'fade',
        loop: true, //是否循环轮播
        speed: 1000, //切换速度
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //自动轮播
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        //设置slider容器能够同时显示的slides数量
        slidesPerView: 1,
        //左右切换
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
      },
      shuipingHeight: null,
    };
  },
  components: {
    Background,
    Banner,
    BannerWall
  },
  computed: {
    ...mapState(['bannerList', 'numItem', 'show', 'total', 'place', 'ShowBanner']),
  },
  mounted() {
    this.img = document.getElementById('footer')
    this.handleBanners()
    this.handelinformation({
      type: 'DEVOTC',
      pageNum: 1,
      pageSize: 6,
      orgId: this.place
    })

    console.log(this.informationTotal)
    // this.timer = setInterval(this.handleTime, 50)
  },
  methods: {
    ...mapActions(['handleBanners', 'handelNum', 'handelinformation']),
    handleClickSlide(index) {
      console.log(index)
    },
    handleTime() {
      // this.num+=2
      if (this.num == 10) {
        this.num = -10
      } else {
        this.num += 2
      }
      this.img.style.marginTop = this.num + "px"
    },
    handleClick(index) {
      if (index == 0) {
        this.$router.push('/empowerment/tree')
        this.$store.commit('setXuan', '/empowerment')
        this.$store.commit('setPath', '/empowerment/tree')
      } else if (index == 1) {
        this.$router.push('/culture/story')
        this.$store.commit('setXuan', '/culture')
        this.$store.commit('setPath', '/culture/story')
      } else if (index == 2) {
        this.$router.push('/rural/park')
        this.$store.commit('setXuan', '/rural')
        this.$store.commit('setPath', '/rural/park')
      } else if (index == 3) {
        this.$router.push('/success/experience')
        this.$store.commit('setXuan', '/success')
        this.$store.commit('setPath', '/success/experience')
      } else {
        this.$router.push('/news/industry')
        this.$store.commit('setXuan', '/news')
        this.$store.commit('setPath', '/news/industry')
      }
    },
    handleMouseenter(index) {
      //移入
      this.MouseIndex = index

    },
    handleMouseleave(index) {
      //移出
      this.MouseIndex = -1

    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
/* .swiper >>> .swiper-pagination-bullet-active {
  background-color: #00ff00;
}

/deep/ .el-carousel__indicators {
  left: unset;
  transform: unset;
  left: 1%;
}

/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background-color: #fff;
}

/deep/ .is-active .el-carousel__button {
  background: #00ff00;
} */

.index {
  width: 100%;
}

.banner {
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
}

.zoumadeng {
  position: relative;
}

.zoumadeng .title {
  position: absolute;
  /* color: red; */
  bottom: 52px;
  right: 120px;
  width: 400px;
  height: 230px;
  background: rgba(0, 0, 0, 0.3);
  padding: 15px 25px 10px 25px;
  color: #ffffff;
}

.zoumadeng .title .name {
  font-size: 20px;
  font-weight: 400;
}

.zoumadeng .title .center {
  font-size: 14px;
  font-weight: 400;
  line-height: 2em;
  height: 84px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.jiantou {
  width: 100%;
  position: absolute;
  height: 100px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.bg {
  width: 100%;
  background-image: url("../../assets/bg.png");
}

.jiantou img {
  width: 31px;
  height: 52px;
}

.shuiping {
  width: 100%;
  display: flex;
  padding: 50px 5%;
}

.shuiping .list img {
  border-radius: 5px;
}

.shuiping .list {
  width: 23.5%;
  height: 120px;
  margin-left: 2%;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
}

.shuiping .list .item {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shuiping .list .item .title {
  color: #fff;
  font-size: 16px;
}

.shuiping .list .item .num {
  color: #fff;
  font-size: 14px;
}

.shuiping .list .item span {
  color: #00ff00;
  font-size: 30px;
  font-weight: 700;
}

.chuizhi {
  width: 100%;
  padding: 0 18% 100px 18%;
}

.chuizhi .chuizhi1 {
  display: flex;
  /* background:red; */
  padding: 0 40px;
}

.chuizhi .chuizhi1 .list {
  width: 22.75%;
  height: 400px;
  margin-left: 3%;
  position: relative;
  transition: margin-top 0.5s;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hr {
  width: calc(100% + 20px);
  height: 21px;
  margin-bottom: 50px;
}

.chuizhi .chuizhi1 .list .item {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chuizhi .chuizhi1 .list img {
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}


.chuizhi .chuizhi1 .list:hover img {
  /* width: 112%;
  height: 112%; */
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(1.1) rotate(3deg);
  -moz-transform: scale(1.1) rotate(3deg);
  -ms-transform: scale(1.1) rotate(3deg);
  -o-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}

.chuizhi .chuizhi1 .list .mengceng {
  position: absolute;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 200px;
  top: 0;
  left: 0;
  height: calc(100% - 80px);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.chuizhi .chuizhi1 .list:hover .mengceng {
  position: absolute;
  margin: 15px;
  width: calc(100% - 30px);
  border: 1px solid #fff;
  height: 200px;
  top: 0;
  left: 0;
  height: calc(100% - 110px);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.chuizhi .list .item .title {
  color: #fff;
  font-size: 14px;
}

@media (max-width: 1919px) {
  .chuizhi .chuizhi1 .list {
    height: 360px;
  }
}

@media (max-width: 1679px) {
  .chuizhi .chuizhi1 .list {
    height: 330px;
  }
}

@media (max-width: 1599px) {
  .chuizhi .chuizhi1 .list {
    height: 300px;
  }

  .shuiping .list {
    height: 100px;
  }
}

@media (max-width: 1365px) {
  .chuizhi .chuizhi1 .list {
    height: 265px;
  }
}
</style>
